// Only run on FAQ page.
// Grab count, remove parens and move inside A tag.

(function () {
  'use strict';
  document.addEventListener('DOMContentLoaded', function() {

    const elem = document.getElementsByClassName('faq-header');

    if (document.body.contains(
      document.getElementsByClassName('faq-header')[0])) {

      for (let i = 0; i < elem.length; i++) {
        let count = elem[i].lastChild.nodeValue;
        let wrapper = document.createElement('span');
        wrapper.setAttribute('class', 'counter');
        wrapper.setAttribute('id', `id-${[i]}`);
        count = count.replace(/\s/g, '');
        count = count.slice(1,-1);
        let targetQuestion = elem[i].getElementsByTagName('a')[0];
        wrapper.textContent = count;
        targetQuestion.append(wrapper);
      }

      // Move the FAQ description from module to sidebar.
      const descrip = document.querySelector('.faq-description');
      const sidebar = document.querySelector('.page--sidebar');
      sidebar.append(descrip);
    }
  });
}());
